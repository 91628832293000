export default {
    tableFields: [
  
      {
        name: 'device.name',
        sortField: 'device.name',
        title: 'Dispositivo',
      //  width: '34%'
      },
      {
        name: 'staff.name',
        sortField: 'staff',
        title: 'Staff',
      //  width: '34%'
      },
      {
        name: 'amount',
        sortField: 'amount',
        title: 'Monto',
      //  width: '34%'
      },
      {
        name: 'user_ktag.codektag',
        sortField: 'user_ktag.codektag',
        title: 'Usuario',
      //  width: '34%'
      },
      {
        name: 'timestamp_formated',
        sortField: 'timestamp_formated',
        title: 'Hora',
        //width: '34%'
      },
    
     
      
    /* {
        name:       '__slot:active',
        title:      'Acciones',
        titleClass: "center aligned",
        dataClass:  "center aligned",
      //  width:      "15%",
    },*/
   /* {
        name:       '__slot:actions',
        title:      'Acciones',
        titleClass: "center aligned",
        dataClass:  "center aligned",
        width:      "180px",
    }*/
  
    ],
    sortFunctions: {
      'name': function (item1, item2) {
        return item1 >= item2 ? 1 : -1
      },
    
    }
  }
  