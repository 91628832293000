<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget>
                    <div class="vuestic-data-table table-responsive" :class="{'data-loading': loading}">
                        <div class="d-flex flex-md-row flex-column align-items-center" :class="controlsAlignmentClass">
                            <filter-bar @filter="onFilterSet" :label="filterInputLabel" v-show="filterInputShown"/>
                            <div class="datatable-controls d-flex flex-row">
                                <div class="form-group">
                                    <slot name="additionalTableControl"></slot>
                                </div>
                            </div>
                            Cantidad: {{dataCount}}
                        </div>
                        <div v-show="loading" class="data-table-loading">
                            <slot name="loading">
                                <spring-spinner slot="loading" :animation-duration="2500" :size="70" color="#4ae387"/>
                            </slot>
                        </div>
                        <vuetable ref="vuetable" :apiUrl="apiUrl"
                                  :apiMode="apiMode" :httpFetch="httpFetch"
                                  :httpOptions="httpOptions" :fields="tableFields"
                                  :dataManager="dataManager" :css="css.table" dataPath="data"
                                  :paginationPath="paginationPathComputed" :appendParams="moreParams"
                                  :perPage="perPage" :queryParams="queryParams" :noDataTemplate="noDataTemplate"
                                  @vuetable:pagination-data="onPaginationData"
                                  @vuetable:loading="onLoading"
                                  @vuetable:loaded="onLoaded">

                            <div slot="active" slot-scope="props">
                                <button type="button" class="btn btn-primary btn-micro"
                                        v-on:click="editStaff(props.rowData)">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </button>

                                <button type="button" v-if="props.rowData.active === 1"
                                        class="btn btn-primary btn-micro" v-on:click="changeActive(props.rowData)">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </button>
                                <button type="button" v-if="props.rowData.active === 0"
                                        class="btn btn-warning btn-micro" v-on:click="changeActive(props.rowData)">
                                    <i class="fa fa-remove" aria-hidden="true"></i>
                                </button>
                                <br>
                            </div>
                        </vuetable>
                        <div class="d-flex justify-content-center mb-4">
                            <vuetable-pagination ref="pagination" @vuetable:row-clicked="actionTable"
                                                 :css="css.pagination" :onEachSide="onEachSide"
                                                 @vuetable-pagination:change-page="onChangePage"/>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
        </div>

    </div>
</template>

<script>

    import BadgeColumn from '../BadgeColumn.vue'
    import FieldsDef from './fields-topup-details'
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import FilterBar
        from '../../../../vuestic-theme/vuestic-components/vuestic-datatable/datatable-components/FilterBar.vue'
    import QueryParams from '../../../../vuestic-theme/vuestic-components/vuestic-datatable/data/query-params'
    import Vue from 'vue'
    import DataTableStyles from '../../../../vuestic-theme/vuestic-components/vuestic-datatable/data/data-table-styles'
    import SpringSpinner from 'epic-spinners/src/components/lib/SpringSpinner'
    import {
        mapState,
        mapActions
    } from 'vuex'

    Vue.component('badge-column', BadgeColumn)

    export default {
        name: 'table-staff',
        components: {
            SpringSpinner,
            FilterBar,
            Vuetable,
            VuetablePagination,
        },
        props: {
            typePay:{
                type:Number
            }, 
            rowData: {
                type: Object
            },
            rowIndex: {
                type: Number
            },
            apiUrl: {
                type: String
            },
            httpFetch: {
                type: Function,
                default: null
            },
            httpOptions: {
                type: Object,
                default: () => {
                }
            },
            filterQuery: {
                type: String,
                default: 'filter'
            },
            perPageSelectorShown: {
                type: Boolean,
                default: true
            },
            filterInputShown: {
                type: Boolean,
                default: true
            },
            filterInputLabel: {
                type: String,
                default: 'Buscar'
            },
            itemsPerPageLabel: {
                type: String,
                default: 'per page'
            },
            paginationPath: {
                type: String,
                default: ''
            },
            queryParams: {
                type: Object,
                default: () => QueryParams
            },
            appendParams: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        data() {
            return {
                apiMode: false,
                sortFunctions: FieldsDef.sortFunctions,
                tableData: {
                    'data': [],
                    'filterableFields': ['name']
                },
                onEachSide: 1,
                tableFields: FieldsDef.tableFields,
                dataModeFilterableFields: ['name'],
                itemsPerPage: [
                    {
                        value: 1
                    },
                    {
                        value: 3
                    }
                ],
                perPage: 20,
                defaultPerPage: 1,
                colorClasses: {},
                filterText: '',
                dataCount: 0,
                css: DataTableStyles,
                loading: false,
                noDataTemplate: '',

            }
        },
        watch: {
            tableData(newVal, oldVal) {
                this.$refs.vuetable.refresh()
            }
        },
        mounted() {
            this.$emit('initialized', this.$refs.vuetable)
        },
        created() {
            var idstation = this.$route.params.idstation;
            console.log(idstation)
            let form ={
                id_station:idstation,
                id_type_pay:this.typePay
            }
            this.loadRefundTransactions(form)
                .then((data) => {
                    let datanew = {
                        'data': data,
                        'filterableFields': ['name']
                    }
                    this.dataCount = data.length;
                    this.tableData = datanew
                })
                .catch(data => {
                    this.addToastMessage({
                        text: 'Error consultando',
                        type: 'warning'
                    })
                })
        },
        computed: {
            ...mapState({
                teams: state => state.team.teams,
                idevent: state => state.event.event.id
            }),
            controlsAlignmentClass() {
                return {
                    'justify-content-md-between': this.filterInputShown,
                    'justify-content-md-end': !this.filterInputShown
                }
            },
            moreParams() {
                // HACK
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.appendParams[this.filterQuery] = this.filterText
                return this.appendParams
            },
            dataModeFilterableFieldsComputed() {
                const dataItem = this.tableData.data[0] || {}
                const filterableFields = this.dataModeFilterableFields

                if (!filterableFields.length) {
                    const itemFields = Object.keys(dataItem)
                    itemFields.forEach(field => {
                        if (typeof dataItem[field] !== 'object') {
                            filterableFields.push(field)
                        }
                    })
                }

                return filterableFields
            },
            filteredTableData() {
                const txt = new RegExp(this.filterText, 'i')

                let filteredData = this.tableData.data.slice()

                filteredData = this.tableData.data.filter((item) => {
                    return this.dataModeFilterableFieldsComputed.some(field => {
                        const val = item[field] + ''
                        return val.search(txt) >= 0
                    })
                })

                return {
                    data: filteredData
                }
            },
            paginationPathComputed() {
                return this.apiMode ? this.paginationPath : 'pagination'
            }
        },
        methods: {
            ...mapActions([
                'addToastMessage',
                'loadRefundTransactions',
                'activeChangeStaff',
                'setTeam'
            ]),
            changeActive(row) {
                this.activeChangeStaff(row.id)
                    .then((data) => {
                        this.addToastMessage({
                            text: data.message,
                            type: 'success'
                        })
                        row.active = data.data.active
                    })
                    .catch(data => {
                        this.addToastMessage({
                            text: data.message,
                            type: 'warning'
                        })
                    })
            },
             showDetailStaff(row) {
                //  console.log(row);
                this.$router.push("/device/event/detail/" + row);
            },
            editStaff(row) {
                this.$router.replace('/staff/update/' + row.id_staff);
                console.log(row);
            },
            actionTable(row) {
                console.log(row);
                this.$router.push('/staff/update/' + row.id_staff);
                // this.setPeriod(row.data);
            },
            onFilterSet(filterText) {
                this.filterText = filterText
                Vue.nextTick(() => this.$refs.vuetable.refresh())
            },
            onItemsPerPage(itemsPerPageValue) {
                this.perPage = itemsPerPageValue
                Vue.nextTick(() => this.$refs.vuetable.refresh())
            },
            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData)
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },
            dataManager(sortOrder, pagination) {
                let data = this.filteredTableData.data
                let sortFunctions = this.sortFunctions

                if (sortOrder.length > 0) {
                    data.sort(function (item1, item2) {
                        const sortField = sortOrder[0].sortField
                        let fn = sortFunctions[sortField]
                        if (fn) {
                            return fn(item1[sortField], item2[sortField])
                        }
                    })

                    if (sortOrder[0].direction === 'desc') {
                        data.reverse()
                    }
                }

                pagination = this.$refs.vuetable.makePagination(data.length)

                return {
                    pagination: pagination,
                    data: data.slice(pagination.from - 1, pagination.to)
                }
            },
            onLoading() {
                this.noDataTemplate = ''
                this.loading = true
                this.$emit('vuestic:loading')
            },
            onLoaded() {
                this.noDataTemplate = this.$t('tables.dataTable.noDataAvailable')
                this.loading = false
                this.$emit('vuestic:loaded')
            }
        }
    }
</script>

<style lang="scss">

    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }

    .vuestic-data-table {
        min-height: 24rem;

        .form-group {
            margin-bottom: 1rem;
        }

        @media (max-width: 1258px) {
            .pagination-link-btn:first-child, .pagination-link-btn:last-child {
                display: none;
            }

            .pagination-link-btn:nth-child(2) {
                border-top-left-radius: $btn-border-radius !important;
                border-bottom-left-radius: $btn-border-radius !important;
            }

            .pagination-link-btn:nth-last-child(2) {
                border-top-right-radius: $btn-border-radius !important;
                border-bottom-right-radius: $btn-border-radius !important;
            }
        }

        @media (max-width: 576px) {
            .hide-not-focused-btn:not(.focus) {
                display: none;
            }
        }

        .data-table-loading {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 40%;
            left: 50%;
        }
    }

    .data-loading {
        opacity: .5;
        pointer-events: none;
    }
</style>
